import { getAuth, signInWithCustomToken } from "firebase/auth";
import { logger } from "../../../lib/logger";

export const loginWithCustomToken = async (customToken: string) => {
  const auth = getAuth();
  console.log(customToken);
  return signInWithCustomToken(auth, customToken)
    .then((cred) => {
      return cred.user.uid;
    })
    .catch((error) => {
      logger(error);
      return null;
    });
};
